<template>
  <section>
    <div class="d-flex flex-column align-start mb-8">
      <h1 class="black--text text-h5 font-weight-medium">Processos</h1>
      <h2 class="text-subtitle-1">
        Acompanhe seu processo na vaga em que se candidatou.
      </h2>
    </div>
    <RenderGenericCards />
  </section>
</template>

<script>
import RenderGenericCards from "@/modules/res/components/RenderGenericCards.vue";

export default {
  name: "ProcessosTab",
  components: {
    RenderGenericCards,
  },
  created() {
    this.loadingTable = true;

    const data = {
      url: "/usuarios/get-processos-seletivo-candidato",
    };

    this.$store
      .dispatch("resCandidatos/getProcessos", data)
      .then((response) => {
        this.genericCards.lengthPages = response.last_page;
        this.genericCards.disabledPagination = false;

        this.disabledInputs = false;
      })
      .finally(() => {
        this.loadingTable = false;
      });
  },
  computed: {
    genericCards: {
      get() {
        return this.$store.getters["resCandidatos/getGenericCards"];
      },
    },
  },
  watch: {
    "genericCards.page"(now, old) {
      const url = `/usuarios/get-processos-seletivo-candidato?page=${now}`;

      const data = {
        url: url,
        // data: {
        //   validate: this.validate,
        // },
      };

      this.$store.dispatch("resCandidatos/getProcessos", data);
    },
  },
};
</script>
